import client from './requestclient';

const plansbillings = {
    userPlans: async () => {
        return await client({URL_PATH: `get-user-smart-plan-data`, method: "GET"})
    },
    getBillingsPlans: async () => {
        return await client({URL_PATH: `fetch-smart-plans`, method: "GET"})
    },
    billPayment: async (body) => {
        return await client({URL_PATH: `smart-plan-subscribe`, method: "POST", body: body})
    },
    // billPaymentWithVoucher: async (body) => {
    //     return await client({URL_PATH: `smart-plan-subscribe`, method: "POST", body: body})
    // },
    
}

export default plansbillings;