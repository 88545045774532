<template>
    <div>
        <div class="modal fade" tabindex="-1" id="billingPlans">
            <div class="modal-dialog modal-xl modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header remove-top-border">
                        <h5 class="modal-title"></h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="select-plan-text-holder container-fluid">
                        <h5>Select Plan</h5>
                        <h6>Choose the best plan for your business needs.</h6>
                        <div class="plans-buttons-select">
                            <button @click="planType = 1" :class="[planType === 1 ? 'activePlans' : '']" class="btn">Individual</button>
                            <button @click="planType = 2" :class="[planType === 2 ? 'activePlans' : '']" class="btn">Business</button>
                        </div>
                    </div>
                    
                    <div class="modal-body plans-holder">
                        <div v-if="planType === 1" class="plans-child-holder">
                            <div class="row">
                                <div class="col-md-6 mb-3" v-for="(item, index) of individualPlans"
                                        :key="index">
                                    <div class="card-holder" >
                                        <div class="plans-top-text" v-if="currentPlan">
                                            <h6 v-if="item.name">{{ item.name }}</h6>
                                            <span v-if="currentPlan && currentPlan.smartplan.name == item.name && currentPlan.confirmed == 'YES'">Renew's is <small v-if="countDown">{{ countDown.days }}</small> days</span>
                                        </div>
                                        <h6 class="description">
                                            {{item.description}}
                                        </h6>
                                        <h5 class="amount">
                                            <span class="slashed-amount" v-if="item.name === 'Smart Agent'">
                                                ₦{{ Number(item.slashed_amount).toLocaleString() }}
                                            </span>
                                            <span v-if="item.name === 'Smart Agent'" class="actual-amount">₦{{ Number(item.amount).toLocaleString() }}</span>
                                            <span v-else class="actual-amount">Free</span>
                                        </h5>
                                        <h6 v-if="item.name === 'Smart Agent'" class="description">Billing {{ item.years_duration == '1' ? 'annualy' : 'triennial' }}</h6>

                                       <div class="plan-btn" v-if="item">
                                          <button class="btn current-plan" v-if="currentPlan && currentPlan.smartplan.name == item.name">Current plan</button>
                                          <button :disabled="currentPlan?.smartplan?.name == 'Smart Agent' ||  currentPlan?.smartplan?.name == 'Exclusive' ||  currentPlan?.smartplan?.name == 'Premium' || currentPlan?.smartplan?.name == 'Alliance'" v-else class="btn choose-plan" data-toggle="modal" data-target="#termscondition" @click="paymentInfo(item)">Become A {{ item.name}}</button>
                                          <!-- <button  data-toggle="modal" data-target="#termscondition">Become A {{ item.name}}</button> -->

                                       </div>

                                       <div class="features">
                                            <h6>Features</h6>
                                            <div class="feature-list" v-for="(features, index) of item.benefits"
                                                :key="index">
                                                <img src="../assets/icons/check-circle.svg" alt="icon">
                                                <p>{{features.data}}</p>
                                            </div>
                                       </div>
                                       <div class="features mt-4">
                                            <h6>Eligibility criteria</h6>
                                            <div class="feature-list"  v-for="(eligible, index) of item.eligibility"
                                                :key="index">
                                                <img src="../assets/icons/check-circle.svg" alt="icon">
                                                <p>{{ eligible.data }}</p>
                                            </div>

                                       </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="planType === 2" class="container">
                            <div class="row">
                                <div class="col-md-4" v-for="(item, index) of businessPlans"
                                    :key="index">
                                    <div class="card-holder">
                                        <div class="plans-top-text">
                                            <h6 v-if="item.name">{{ item.name }}</h6>
                                            <span v-if="currentPlan && currentPlan?.smartplan?.name == item.name && currentPlan.confirmed == 'YES'">Renew's is <small v-if="countDown">{{ countDown.days }}</small> days</span>
                                        </div>
                                        <h6 class="description">
                                            {{item.description}}
                                        </h6>
                                        <h5 class="amount">
                                            <span class="slashed-amount">₦{{ Number(item.slashed_amount).toLocaleString() }}</span>
                                            <span class="actual-amount">₦{{ Number(item.amount).toLocaleString() }}</span>
                                        </h5>
                                        <!-- <h6 class="description">Billing annualy</h6> -->
                                        <h6 class="description">Billing {{ item.years_duration == '1' ? 'annualy' : 'triennial' }}</h6>
                                        <h6 class="description" v-if="item.name == 'Premium' || item.name == 'Alliance'">Slots: {{ item.slots_per_state }} 
                                            <span class="tooltipp">
                                                !
                                                <small class="tooltipptext">Only {{ item.available_slots }} slots remaining for the Premium Plan in your state</small>
                                            </span>
                                        </h6>

                                        <!-- Exclusive -->
                                        <!-- Premium -->
                                        <!-- Alliance -->

                                       <div class="plan-btn" v-if="item || currentPlan">
                                          <button class="btn current-plan" v-if="currentPlan && currentPlan?.smartplan?.name == item.name">Current plan</button>
                                          <button v-else :disabled="item.name == 'Alliance' && currentPlan?.smartplan?.name == 'Alliance' ||  (currentPlan?.smartplan?.name == 'Premium' && item.name == 'Exclusive') || (currentPlan?.smartplan?.name == 'Alliance' && item.name == 'Exclusive') || (currentPlan?.smartplan?.name == 'Alliance' && item.name == 'Premium')" class="btn choose-plan" data-toggle="modal" data-target="#termscondition" @click="paymentInfo(item)">Get {{ item.name}}</button>
                                       </div>

                                       <div class="features">
                                            <h6>Includes all Smart agent benefits, and</h6>
                                            <div class="feature-list"  v-for="(features, index) of item.benefits"
                                                :key="index">
                                                <img src="../assets/icons/check-circle.svg" alt="icon">
                                                <p>{{ features.data }}</p>
                                            </div>
                                       </div>

                                       <div class="features mt-4">
                                            <h6>Eligibility criteria</h6>
                                            <div class="feature-list" v-for="(eligible, index) of item.eligibility"
                                                :key="index">
                                                <img src="../assets/icons/check-circle.svg" alt="icon">
                                                <p>{{ eligible.data }}</p>
                                            </div>
                                       </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                       
                    </div>
               
                </div>
            </div>
        </div>

        <!-- terms and condtion modal starts -->
        <div class="modal fade" tabindex="-1" id="termscondition">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title modal-top-text">Terms & Condtions</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <!-- <p class="modal-top-text">Terms & Condtions</p> -->

                        <h6 class="modal-content-body">
                            <div class="terms-condition-body">
                                <h5>Welcome to the Smart Partners Plan (SPP) offered by Shelta Panacea Ltd. By accessing or participating in the Smart Partners Plan, you agree to comply with the following terms and conditions. These terms establish the legally binding rules that govern your participation in the SPP and use of Shelta’s platform, resources, and services. Please read them carefully before committing to the partnership.</h5><br>
                                <h6>Definitions</h6>
                                <p>“Shelta” refers to Shelta Panacea Ltd and its affiliates.</p>
                                <p>“SPP” refers to the Smart Partners Plan, Shelta’s partnership program for individuals or organizations involved in property management, real estate, and related activities.</p>
                                <p>“Partner” refers to any individual or organization participating in the Smart Partners Plan.</p>
                                <p>“Services” refers to the resources, tools, and systems provided by Shelta to support Partners.</p>
                                <p>“Users” refers to landlords, tenants, agents, vendors, and other stakeholders engaged through Shelta’s platform <br><br> </p>

                                <h6>Eligibility</h6>
                                <p>To be eligible for the Smart Partners Plan, you must:</p>
                                <p>Be at least 18 years old.</p>
                                <p>Be a Smart Associate</p>
                                <p>Possess a valid means of identification for Know Your Customer (KYC) verification.</p>
                                <p>Have the capacity to enter into legally binding agreements under applicable law.</p>
                                <p>Comply with all applicable laws and regulations relating to the services offered. <br></p>
                                <p>For higher partnership tiers (EXCLUSIVE, PREMIUM, ALLIANCE), additional requirements may apply, such as business registration, office space, and staff training capabilities.</p>

                                <h6> Partnership Structure</h6>
                                <p>The Smart Partners Plan offers several tiers of participation, each with distinct benefits and requirements:</p>
                                <!-- <p>STANDARD (Smart Associate Subscription)</p> -->
                                <p>AGENT</p>
                                <p>EXCLUSIVE</p>
                                <p>PREMIUM</p>
                                <p>ALLIANCE</p>
                                <br>
                                <p>Partners may choose the tier that aligns with their business objectives. Fees, benefits, and responsibilities vary across tiers. Each Partner must agree to the specific conditions of their chosen tier.</p>

                                <h6>Partner Responsibilities</h6>
                                <p>As a Smart Partner, you agree to:</p>
                                <p>Promote Shelta’s services in compliance with Shelta’s branding and guidelines.</p>
                                <p>Participate in property management activities, rent collection, and tenant engagement as outlined in the SPP.</p>
                                <p>Register landlords, tenants, Smart Associates, and agents.</p>
                                <p>Facilitate training and onboarding sessions for new users, including landlords, tenants, vendors, and other agents.</p>
                                <p>Lead and manage your assigned community of Shelta Associates, including vendors, agents, facility managers, and inspection officers.</p>
                                <p>Failure to fulfill these responsibilities may result in termination or suspension of your partnership.</p> <br><br>

                                <h6>Fees and Payments</h6>
                                <p>All fees payable under the Smart Partners Plan must be paid through Shelta’s approved payment gateway (Paystack or other designated systems). The fees vary according to the partnership tier:</p>
                                <!-- <p>STANDARD: ₦20,000 annual fee.</p> -->
                                <p>AGENT: ₦50,000 annual fee.</p>
                                <p>EXCLUSIVE: ₦200,000 annual fee.</p>
                                <p>PREMIUM: ₦2,000,000 annual fee.</p>
                                <p>ALLIANCE: ₦20,000,000 one-time fee.</p>
                                <p>All fees are non-refundable, except as required by law.</p> <br><br>

                                <h6>Earning Potential and Revenue Sharing</h6>
                                <p>Smart Partners have the potential to earn income from the following sources:</p>
                                <p>Property management activities.</p>
                                <p>Rent collection and flexible payment services.</p>
                                <p>Property sales and rentals.</p>
                                <p>Admin fees for property management and rentals</p>
                                <p>Gap financing and real estate investment opportunities.</p>
                                <p>Revenue-sharing percentages and specific earning opportunities vary by partnership tier. Shelta reserves the right to modify revenue-sharing arrangements at its discretion, provided that any such changes will be communicated in writing.</p> <br><br>

                                <h6>Support and Training</h6>
                                <p>Shelta provides extensive support to Partners, including:</p>
                                <p>Comprehensive training on sales, marketing, negotiation, property valuation, and other relevant skills.</p>
                                <p>Access to advanced property management tools and technology.</p>
                                <p>Ongoing mentorship and guidance to help you grow your business.</p>
                                <p>Partners are expected to attend mandatory training sessions and make use of the resources provided to ensure their success in the program.</p>
                                <br><br>

                                <h6>Marketing and Advertising</h6>
                                <p>Partners are encouraged to promote Shelta’s services and properties using Shelta’s marketing resources. Shelta will provide shared marketing support, including:</p>
                                <p>Targeted lead generation campaigns</p>
                                <p>Co-branded marketing materials</p>
                                <p>Online and offline advertising support.</p>
                                <p>All promotional activities must adhere to Shelta’s guidelines and must not misrepresent Shelta’s brand, services or products.</p> <br><br>

                                <h6>Technology and Systems Access</h6>
                                <p>Partners are granted access to Shelta’s proprietary technology, including tools for:</p>
                                <p>Lead generation and customer relationship management.</p>
                                <p>Rent collection and flexible payment facilitation.</p>
                                <p>Property listing and management.</p>
                                <p>Communication and document sharing with clients and users.</p>
                                <p>Partners must not misuse or distribute Shelta’s technology or systems in any way that violates intellectual property laws or endangers the security of the platform.</p><br><br>

                                <h6>Confidentiality and Data Protection</h6>
                                <p>As a Smart Partner, you may have access to sensitive information, including user data and proprietary business practices. You agree to:</p>
                                <p>Maintain the confidentiality of all such information.</p>
                                <p>Use personal data only for legitimate business purposes and in compliance with applicable data protection laws.</p>
                                <p>Report any data breaches or security incidents immediately to Shelta.</p> <br><br>

                                <h6>Termination and Suspension</h6>
                                <p>Shelta reserves the right to terminate or suspend a Partner’s participation in the Smart Partners Plan for any of the following reasons:</p>
                                <p>Breach of these terms and conditions.</p>
                                <p>Misrepresentation or fraud.</p>
                                <p>Failure to comply with applicable laws and regulations.</p>
                                <p>Non-payment of fees.</p>

                                <p>In the event of termination, all rights and privileges under the Smart Partners Plan will cease, and the Partner must return any confidential information or materials obtained during the partnership.</p><br><br>

                                <h6>Intellectual Property</h6>
                                <p>All content, materials, systems, and technology provided by Shelta are the exclusive intellectual property of Shelta Panacea Ltd. Partners may use these resources only as authorized by Shelta and solely for the purposes of conducting business under the Smart Partners Plan.</p>
                                <br><br>

                                <h6>Limitation of Liability</h6>
                                <p>Shelta will not be liable for any indirect, incidental, or consequential damages arising out of your participation in the Smart Partners Plan. Shelta’s total liability will not exceed the fees paid by the Partner in the 12 months preceding any claim.</p>
                                <br><br>

                                <h6>Governing Law and Dispute Resolution</h6>
                                <p>These terms and conditions are governed by the laws of the Federal Republic of Nigeria. Any disputes arising from your participation in the Smart Partners Plan must be resolved through mediation or arbitration, as determined by Shelta.</p>
                                <br><br>
                                <h6>Modifications to Terms</h6>
                                <p>Shelta reserves the right to modify these terms and conditions at any time. Any changes will be communicated to Partners in writing, and continued participation in the Smart Partners Plan constitutes acceptance of the modified terms.</p>
                                <br><br>
                                <h6>Contact Information</h6>
                                <p>For any questions or concerns regarding these terms and conditions, please contact Shelta at partners@myshelta.com</p>

                            </div>
              
                            
                        </h6>

                        <div class="agree-btn">
                            <button class="btn" @click="openPlansOptionsModal()" :disabled="paymentLoader">
                                I Agree, Continue
                                Continue
                               <i
                                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                                v-if="paymentLoader"
                            ></i>
                            </button>
                        </div>
                    </div>
               
                </div>
            </div>
        </div>
        <!-- terms and condtion modal ends -->
    </div>
</template>


<script>
// import authentication from '../services/authentication';
import { EventBus } from './EventBus';
import plansbillings from '../services/plansBillings';
import $ from "jquery";
export default {
    name: "PlansBillingsModal",
    props: ['currentPlan', 'countDown'],
    data() {
        return {
            paymentLoader: false,
            loading: false,
            planType: 1,
            individualPlans: [],
            businessPlans: [],
            plansOptionType: 0,
        }
    },
    created() {
        this.$root.$refs.plans = this;
    },
    mounted() {
        this.fetchAllBillingPlans();
    },
    methods: {
        plansModal() {
            $("#billingPlans")
            .addClass("fade")
            .modal("show");
        },
        openPlansOptionsModal() {
           if (this.plansOptionType != 1) {
            
            $("#billingPlans")
            .removeClass("fade")
            .modal("hide");
            $("#termscondition")
            .removeClass("fade")
            .modal("hide");
            return this.$root.$refs.plansOptions.plansOptionsModal();
           } else {
            this.submitPayment()
           }
        },
        closePlansModal() {
            $("#billingPlans")
            .removeClass("fade")
            .modal("hide");
        },
        submitPayment() {
            // console.log("active payment", this.activePayment)
            this.paymentLoader = true;
            let body = {
                smart_plan_id: 1,
                payment_method: this.activePayment,
                amount:  0,
            }
            body = JSON.stringify(body);
            plansbillings.billPayment(body)
            .then((res) => {
                // console.log("Payment response", res);
                this.paymentLoader = false;
                if (res.success === "success") {
                    location.reload();
                    $("#termscondition")
                    .removeClass("fade")
                    .modal("hide");
                    this.$toast.success("Payment successful");
                }
                if (res.error) {
                    this.$toast.error(res.error);
                    this.paymentLoader = false;
                    $("#termscondition")
                    .removeClass("fade")
                    .modal("hide");
                }
            })
            .catch((err) => {
                console.log("err", err);
                this.$toast.error(err);
                $("#termscondition")
                    .removeClass("fade")
                    .modal("hide");
                // return this.$store.commit("setApiFailed", "Error occured");
            });

        },
        paymentInfo(payment) {
            this.plansOptionType = payment.id;
            EventBus.$emit('dataForPayment', payment);
            // this.$emit('dataForPayment', payment);
        },
        async fetchAllBillingPlans() {
            this.loading = true;
            await plansbillings.getBillingsPlans().then(data => {
                // console.log("All plans and billings", data)
                this.loading = false;
                if (data.success) {
                    this.individualPlans = data.plans.filter(item => item.plan_type === 'individual');
                    this.businessPlans = data.plans.filter(item => item.plan_type === 'business');
                } else {
                    this.$toast.error(data.error);
                }
            }).catch((err) => {
                this.loading = false;
                console.log(err);
                // this.$toast.error("please check your network and refresh the page");
           });
        },
    },
}
</script>


<style lang="scss" scoped>
$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$asheGrey: #555C74;
$offblack: #2B3352;

.select-plan-text-holder {
    h5 {
        font-family: Lato;
        font-size: 32px;
        font-weight: 600;
        line-height: 28px;
        text-align: center;
        color: $offblack;
    }
    h6 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: $asheGrey;
    }
}

.plans-buttons-select {
    margin-top: 20px !important;
    width: fit-content;
    margin: auto;
    border: 1px solid #EAECF0;
    border-radius: 50px;
    button {
        font-family: Lato;
        font-size: 12px;
        font-weight: 400;
        // line-height: 18px;
        text-align: center;
        color: $asheGrey;
        box-shadow: none;
        outline: 0;
    }
    .activePlans {
        background: $primary;
        color: $white;
        border-radius: 50px;
    }
}

.remove-top-border {
    border-bottom: none;
}

.plans-holder {
    margin-top: 30px;
    margin-bottom: 40px;
}


.plans-top-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    h6 {
        font-family: Lato;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        color: $offblack;
        margin-bottom: 0 !important;
    }
    span {
        font-family: Lato;
        font-size: 10px;
        font-weight: 600;
        line-height: 16px;
        text-align: center;
        color: $asheGrey;
        border-radius: 50px;
        border: 1px solid $asheGrey;
        padding: 5px 10px;
        small {
            font-family: Lato;
            font-size: 10px;
            font-weight: 600;
            line-height: 16px;
            text-align: center;
            color: $asheGrey;
            // padding: 5px 10px;
        }
    }
}

.card-holder {
    border-radius: 20px;
    border: 1px solid #EAECF0;
    padding: 15px;
}
.description {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: $asheGrey;
    margin-bottom: 10px;
    span {
        margin-left: 8px;
        border: 1px solid #555C74;
        border-radius: 50px;
        padding: 2px 7px;
        cursor: pointer;
        position: relative;
        font-size: 11px;
    }
}
.amount {
    span {
        font-family: Lato;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;

    }
    .slashed-amount {
        color: #2B33524D;
        margin-right: 10px;
        text-decoration: line-through;
    }
    .actual-amount {
        color: $offblack;
    }
}

.plan-btn {
    margin-top: 35px;
    margin-bottom: 30px;
    button {
        height: 44px;
        border-radius: 8px;
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        width: 100%;
        box-shadow: none;
        outline: 0;
    }
    .current-plan {
        border: 1px solid #aaaaaa;
        color: #aaaaaa;
        cursor: not-allowed;
    }
    .choose-plan {
        border: 1px solid $primary;
        color: $primary;
    }
}

.features {
    margin-top: 20px;
    h6 {
        font-family: Lato;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
        color: $asheGrey;
        margin-bottom: 15px;
    }

}

.feature-list {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    p {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: $asheGrey;
        margin-left: 8px !important;
        margin-bottom: 0 !important;
    }
}

.modal-top-text {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;

}
.modal-content-body {
    margin-top: 10px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 200;
    line-height: 24px;
    text-align: justified !important;

}
.agree-btn {
    margin-top: 40px;
    margin-bottom: 20px;
    button {
        color: $white;
        background: $primary;
        outline: 0;
        box-shadow: none;
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        border-radius: 8px;
        height: 48px;
        width: 100%;
    }
}

.tooltipp {
  position: relative;
//   display: inline-block;
  cursor: pointer;
}

.tooltipp .tooltipptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: white;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  bottom: 125%; /* Adjust this value */
  left: 50%;
  margin-left: -60px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltipp .tooltipptext::after {
  content: "";
  position: absolute;
  top: 100%; /* Arrow position */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltipp:hover .tooltipptext {
  visibility: visible;
  opacity: 1;
}

.terms-condition-body {
    h5, h6, p {
        font-family: Lato !important;
        font-style: normal;
        // font-size: 16px;
        line-height: 30px;
        text-align: left;
    }
    h5 {
        color: $offblack;
        font-size: 16px;
        font-weight: 400;
    }
    h6 {
        color: $offblack;
        font-size: 16px;
        font-weight: 400;
    }
    p {
        color: $asheGrey;
        font-size: 14px;
        font-weight: 400;
    }
}


@media screen and (max-width: 599px) { 
    .col-md-4, .col-md-6 {
        margin-bottom: 15px;
    }
}

@media (min-width: 1200px) {
    .plans-child-holder {
        margin: 0 17%;
    }
    .card-holder {
        min-height: 749px;
    }
}

</style>