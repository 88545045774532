<template>
    <div>
        <div
        tabindex="-1"
        class="modal fade"
        id="voucherModal"
        role="dialog"
        aria-labelledby="sub"
        aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="margin-bottom: 30px">
                <div class="modal-header">
                    <h5 class="modal-title confirmation-header">
                    Pay with voucher
                    </h5>
                    <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span
                        @click="paymentType = ''"
                        aria-hidden="true"
                        style="font-weight: normal"
                        >X</span
                    >
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                    <input @input="formatInputRemoveCharacters" maxlength="19" :value="formattedInput" class="form-control input-text" type="text" name="vocher" id="voucher" placeholder="Enter voucher numbers">
                    </div>
                </div>
                <div class="modal-footer modal-button">
                    <button
                    @click="voucherNumber = ''"
                    type="button"
                    class="btn cancel voucherCancel"
                    data-dismiss="modal"
                    >
                    Cancel
                    </button>
                    <button
                    :disabled="voucherLoading"
                    ref=""
                    type="button"
                    class="btn continue voucherPay"
                    @click="payPlanWithVoucher()"
                    >
                    Pay
                    <i
                        class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                        v-if="voucherLoading"
                    ></i>
                    </button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import $ from "jquery";
// import transactionPin from "../services/transactionPin";
import plansbillings from '../services/plansBillings';
export default {
    name: "TransactionPinVerification",
    props: {
        voucherPayment: Object
    },
    data() {
        return {
            loading: false,
            voucherLoading: false,
            verifing: false,
            pinNumber: null,
            voucherNumber: "",
            // parentData: ""
        }
    },
    created() {
        this.$root.$refs.voucherpin = this;
    },
    computed: {
        formattedInput() {
          return this.formatWithHyphens(this.voucherNumber);
        },
    },
    mounted() {
    },
    methods: {
        formatInputRemoveCharacters(event) {
            let value = event.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric values
            value = value.slice(0, 16); // Limit to 16 digits (without hyphens)
            this.voucherNumber = value; // Update the raw input
        },
        formatWithHyphens(value) {
            return value.replace(/(\d{4})(?=\d)/g, "$1-"); // Add hyphen after every 4 digits except last
        },
        openVoucherPinModal() {
            $("#voucherModal")
            .addClass("fade")
            .modal("show");
        },
        closeVoucherPinModal() {
            $("#voucherModal")
            .removeClass("fade")
            .modal("hide");
        },
        payPlanWithVoucher() {
            this.voucherLoading = true;
            if (this.voucherNumber === "") {
                this.voucherLoading = false;
                return this.$toast.error("Enter voucher numbers");
            }
            let body = {
                smart_plan_id: this.voucherPayment.smart_plan_id,
                payment_method: this.voucherPayment.payment_method,
                amount:  this.voucherPayment.amount,
                voucher: this.voucherNumber,
            }
            plansbillings.billPayment(JSON.stringify(body)).then(res => {
                // console.log("res", res);
                this.voucherLoading = false;
                if (res.success) {
                $("#voucherModal")
                    .removeClass("fade")
                    .modal("hide");
               
                this.$toast.success("Subscription successful");
                // return this.$store.commit("setApiSuccess", "Subscription successful");
                }
                if (res.error) {
                this.voucherNumber = "";
                return this.$toast.error(res.error);
                }
            }).catch((err) => {
                this.voucherNumber = "";
                console.log(err);
                this.voucherLoading = false;
            });
        },
    },
}
</script>



<style scoped lang="scss">

$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$asheGrey: #555C74;
$offblack: #2B3352;

$shelta-black: #000a2f;
$shelta-gray: #555c74;

$secondary: #ffffff;
$black: #000000;
$dline: #dddddd;

.main-footer-button {
  justify-content: space-around !important;
}
.voucherCancel, .voucherPay {
  width: 45% !important;
}
.modal-button {
  // padding-top: 24px;
  // padding-bottom: 40px;
  padding-top: 15px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  border-top: none !important;

  .cancel {
    border-radius: 6px;
    border: 1px solid $dline;
    color: $shelta-black;
    width: 178px;
    height: 48px;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    outline: 0;
    box-shadow: none;
  }

  .continue {
    border-radius: 6px;
    background: $primary;
    color: $secondary;
    // width: 178px;
    min-height: 48px;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    outline: 0;
    box-shadow: none;
  }
}
.input-text {
  font-family: Lato;
  font-weight: 400;
  font-size: 16px;
  box-shadow: none;
  outline: 0;
  color: #555C74;
  height: 60px;

}
</style>